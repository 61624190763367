import React from "react";

import { Flex, Header } from "Atoms";
import { Order } from "Types";
import { CateringAddressContent, CateringBoldAndLightText } from ".";
import { useLanguage } from "LanguageProvider";

type Props = {
    order: Order;
};

export const CateringDeliveryInformation: React.FC<Props> = ({ order }) => {
    const { translate, translateWithArgument } = useLanguage();
    const {
        invoiceData,
        userInformation: { onlineContactInformation }
    } = order;

    let district = "";
    if (order.invoiceData) {
        district = invoiceData.invoiceAddress.city;
    } else if (onlineContactInformation.addressLine) {
        district =
            onlineContactInformation.city || translateWithArgument("missingWithArg", translate("city").toLowerCase());
    }

    return (
        <Flex direction="column" bg="gray.200" p={4} rounded="lg" mt={[0, 0, 8]} height="auto">
            <Header size="lg" as="h3" fontWeight="normal" mb={6}>
                Leveransuppgifter
            </Header>
            <Flex direction="column">
                <CateringAddressContent
                    title="Leverans adress"
                    address={
                        onlineContactInformation.addressLine ||
                        translateWithArgument("missingWithArg", translate("address").toLowerCase())
                    }
                    postCode={
                        onlineContactInformation.postCode ||
                        translateWithArgument("missingWithArg", translate("postCode").toLowerCase())
                    }
                    district={district}
                    mb={4}
                />
                {!!onlineContactInformation.doorCode && (
                    <CateringBoldAndLightText title="Portkod" value={onlineContactInformation.doorCode} />
                )}
                {!!onlineContactInformation.floorNumber && (
                    <CateringBoldAndLightText title="Våning" value={onlineContactInformation.floorNumber} />
                )}
            </Flex>
        </Flex>
    );
};
