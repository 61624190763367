import React from "react";

import { Flex } from "Atoms";
import { Order } from "Types";
import {
    CateringContactInformation,
    CateringCompanyInformation,
    CateringDeliveryInformation,
    CateringProducts
} from ".";
import { useLanguage } from "LanguageProvider";

type Props = {
    order: Order;
    displayTotalPrice?: boolean;
    canSelectPrices?: boolean;
};

export const CateringCompanyDelivery: React.FC<Props> = ({
    order,
    displayTotalPrice = false,
    canSelectPrices = true
}) => {
    const { translate, translateWithArgument } = useLanguage();
    const {
        userInformation: { onlineContactInformation },
        orderProducts,
        invoiceData
    } = order;
    const refPhone =
        onlineContactInformation.phoneNumber2 ||
        onlineContactInformation.phoneNumber ||
        translateWithArgument("missingWithArg", translate("phoneNumber").toLowerCase());

    return (
        <Flex direction="column" height="auto">
            <Flex justify="space-between" height="auto" direction={["column", "column", "row"]}>
                <CateringContactInformation
                    refName={onlineContactInformation.name}
                    refPhone={refPhone}
                    refEmail={onlineContactInformation.email || "Missing email"}
                />
                <CateringCompanyInformation invoiceData={invoiceData} phoneNumber={refPhone} />
            </Flex>
            <CateringDeliveryInformation order={order} />
            <CateringProducts
                orderProducts={orderProducts}
                displayTotalPrice={displayTotalPrice}
                canSelectPrices={canSelectPrices}
            />
        </Flex>
    );
};
