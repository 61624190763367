import React from "react";

import { Box, Flex } from "Atoms";
import { Order } from "Types";
import { CateringContactInformation, CateringCompanyInformation, CateringProducts } from ".";
import { useLanguage } from "LanguageProvider";

type Props = {
    order: Order;
    displayTotalPrice?: boolean;
    canSelectPrices?: boolean;
};

export const CateringCompanySelfPickup: React.FC<Props> = ({ order, displayTotalPrice, canSelectPrices = true }) => {
    const { translate, translateWithArgument } = useLanguage();
    const {
        userInformation: { onlineContactInformation },
        orderProducts,
        invoiceData
    } = order;
    const refPhone =
        onlineContactInformation.phoneNumber2 ||
        onlineContactInformation.phoneNumber ||
        translateWithArgument("missingWithArg", translate("phoneNumber").toLowerCase());

    return (
        <Box>
            <Flex direction="column">
                <Flex justify="space-between" direction={["column", "column", "row"]}>
                    <CateringContactInformation
                        refName={onlineContactInformation.name}
                        refPhone={refPhone}
                        refEmail={
                            onlineContactInformation.email ||
                            translateWithArgument("missingWithArg", translate("email").toLowerCase())
                        }
                    />
                    <CateringCompanyInformation invoiceData={invoiceData} phoneNumber={refPhone} />
                </Flex>
                <CateringProducts
                    orderProducts={orderProducts}
                    displayTotalPrice={displayTotalPrice}
                    canSelectPrices={canSelectPrices}
                />
            </Flex>
        </Box>
    );
};
