import React from "react";
import _ from "lodash";

import { Header, Text, Box, Flex, NewList, NewListItem } from "Atoms";
import { OnlineAddonsList, OnlineBundleItemsList } from "Organisms";
import { CustomerModificationsList } from "../../../customer/pages/orderStatus/components";
import { formatSwedishFinancialNumbers } from "TempUtils";
import { OrderProduct } from "Types";
import { GLOBAL_DELIVERY_FEE_ORDER_PRODUCT_NAME } from "Constants";
import { useLanguage } from "Providers";

type Props = {
    orderProducts: OrderProduct[]
    displayTotalPrice?: boolean;
    canSelectPrices?: boolean;
};

export const CateringProducts: React.FC<Props> = ({
    orderProducts,
    displayTotalPrice = false,
    canSelectPrices = true
}) => {
    const { translate } = useLanguage();
    return (
        <Box bg="gray.200" p={4} mt={8} rounded="lg">
            <Flex direction="column">
                <Box>
                    <Header size="lg" as="h3" fontWeight="normal" mb={6}>
                        Produkter
                    </Header>
                    <NewList stretch={4} pl={4}>
                        {orderProducts
                            .sort(
                                (a: any, b: any) =>
                                    a.refProductCategoryId &&
                                    a.refProductCategoryId.localeCompare(b.refProductCategoryId)
                            )
                            .map((orderProduct, i) => {
                                if (orderProduct.name === GLOBAL_DELIVERY_FEE_ORDER_PRODUCT_NAME) {
                                    return;
                                }
                                const selectedBundleProductItems = orderProduct.selectedBundleProductItems;
                                selectedBundleProductItems?.map(bundle => {
                                    const addons = bundle.addons.sort((a, b) => a.name.localeCompare(b.name));
                                    return {
                                        ...bundle,
                                        addons: addons
                                    };
                                });
                                const bundleItemsWithoutDuplicates = [
                                    ...new Set(selectedBundleProductItems?.map(item => JSON.stringify(item)))
                                ]
                                    .map(item => JSON.parse(item))
                                    .map(bundle => {
                                        const _bundle = selectedBundleProductItems?.filter(prod =>
                                            _.isEqual(prod, bundle)
                                        );
                                        if (!!_bundle)
                                            return {
                                                ..._bundle[0],
                                                name: `${_bundle && _bundle.length > 1 ? _bundle.length + "x " : "  "}${_bundle[0]?.name
                                                    }`
                                            };
                                    });
                                const hasProductComment = !!orderProduct.comment;
                                return (
                                    <NewListItem key={i}>
                                        <Flex justify={displayTotalPrice ? "space-between" : "flex-start"}>
                                            <Text mb={2} fontSize="lg">
                                                {orderProduct.quantity}x {orderProduct.name}
                                            </Text>
                                            {displayTotalPrice && (
                                                <Text
                                                    mb={2}
                                                    fontSize="lg"
                                                    userSelect={canSelectPrices ? "all" : "none"}
                                                >
                                                    {formatSwedishFinancialNumbers(orderProduct.totalPrice)}
                                                </Text>
                                            )}
                                        </Flex>
                                        <OnlineAddonsList addons={orderProduct.addons} quantityIndicator="x" />
                                        <CustomerModificationsList
                                            modifications={orderProduct.modifications}
                                            productPrice={orderProduct.totalPrice}
                                        />
                                        <OnlineBundleItemsList
                                            //@ts-ignore
                                            selectedBundleItems={bundleItemsWithoutDuplicates}
                                        />
                                        {hasProductComment && (
                                            <Text mt={2} fontSize="lg">
                                                {`${translate("comment")}: ${orderProduct.comment}`}
                                            </Text>
                                        )}
                                    </NewListItem>
                                );
                            })}
                    </NewList>
                </Box>
            </Flex>
        </Box>
    );
};
