import React from "react";

import { Box, Flex } from "Atoms";
import { CateringPayment } from "./CateringPayment";
import { Order } from "Types";
import { CateringContactInformation, CateringProducts } from ".";
import { useLanguage } from "LanguageProvider";

type Props = {
    order: Order;
    displayTotalPrice?: boolean;
    canSelectPrices?: boolean;
};

export const CateringPrivateSelfPickup: React.FC<Props> = ({
    order,
    displayTotalPrice = false,
    canSelectPrices = true
}) => {
    const { translate, translateWithArgument } = useLanguage();
    const {
        userInformation: { onlineContactInformation },
        orderProducts
    } = order;
    const refPhone =
        onlineContactInformation.phoneNumber2 ||
        onlineContactInformation.phoneNumber ||
        translateWithArgument("missingWithArg", translate("phoneNumber").toLowerCase());

    return (
        <Box>
            <Flex direction="column">
                <CateringContactInformation
                    refName={onlineContactInformation.name}
                    refPhone={refPhone}
                    refEmail={
                        onlineContactInformation.email ||
                        translateWithArgument("missingWithArg", translate("email").toLowerCase())
                    }
                    isFullWidth
                />
                <CateringProducts
                    orderProducts={orderProducts}
                    displayTotalPrice={displayTotalPrice}
                    canSelectPrices={canSelectPrices}
                />
                <CateringPayment paymentMethodAmounts={order.paymentMethodAmounts} />
            </Flex>
        </Box>
    );
};
